import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import minifyTheme from "minify-css-string";

Vue.use(Vuetify, {});

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: "#8FC431",
        secondary: "#060505",
        background: "#FFFFFF",
      },
      dark: {
        primary: "#8FC431",
        secondary: "#060505",
        background: "#060505",
      },
    },
    options: {
      minifyTheme,
      customProperties: true,
    },
  },
});

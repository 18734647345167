<template>
  <v-app style="background-color: var(--v-background-base)" app>
    <app-bar />
    <v-main>
      <hero-img />
      <go-to />
      <about-section />
      <!-- <silent-rain-section /> -->
      <social-section />
      <back-to-top />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./layout/AppBar";
import HeroImg from "./components/HeroImg.vue";
import GoTo from "./components/GoTo.vue";
// import SilentRainSection from "./components/SilentRainSection.vue";
import AboutSection from "./components/AboutSection.vue";
import SocialSection from "./components/SocialSection.vue";
import BackToTop from "./components/BackToTop.vue";

export default {
  components: {
    AppBar,
    HeroImg,
    GoTo,
    // SilentRainSection,
    AboutSection,
    SocialSection,
    BackToTop,
  },
};
</script>

<style>
.text-green {
  color: var(--v-primary-base);
}
.text-black {
  color: var(--v-secondary-base);
}
.text-bold {
  font-family: "Inter-Bold";
}
.text-white {
  color: #ffffff;
}
.text-regular-black {
  font-family: "Inter-Regular";
  color: #060505;
}
.text-thin-white {
  font-family: "Inter-Light";
  color: #ffffff;
  font-size: x-large;
}
.text-thin-white1 {
  font-family: "Inter-Light";
  color: #ffffff;
  font-size: 15px;
}
.text-thin-white2 {
  font-family: "Inter-Light";
  color: #ffffff;
  font-size: 20px;
}
.title-thin-white {
  font-family: "Inter-Light";
  color: #ffffff;
  font-size: 40px;
}
.text-semibold-black {
  font-family: "Inter-SemiBold";
  color: #060505;
}
.text-regular-black {
  font-family: "Inter-Regular";
  color: #060505;
}
.text-medium-black {
  font-family: "Inter-Medium";
  color: #060505;
}
.text-bold-black {
  font-family: "Inter-Bold";
  color: #060505;
}
.text-bold-white {
  font-family: "Inter-Bold";
  color: #ffffff;
}
.title-amaticR-white {
  font-family: "Jura-Regular";
  color: #ffffff;
  font-size: 45px;
}
.title-amaticB-white {
  font-family: "Jura-Bold";
  color: #ffffff;
  font-size: 4rem;
}
.text-size-1 {
  font-size: 1.1rem;
}
@media all and (max-width: 22rem) {
  .title-amaticB-white {
    font-size: 3rem;
  }
}
.text-shadow-1 {
  text-shadow: 1px 2px 1px rgba(255, 255, 255, 0.2);
}
.text-shadow-2 {
  text-shadow: 1px 3px 1px rgba(0, 0, 0, 0.2);
}
.text-shadow-3 {
  text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);
}
.text-shadow-4 {
  text-shadow: 3px 2px 4px rgba(0, 0, 0, 0.2);
}
.text-shadow-5 {
  text-shadow: 4px 3px 5px rgba(0, 0, 0, 0.2);
}
.text-shadow-6 {
  text-shadow: 5px 3px 5px rgba(0, 0, 0, 0.2);
}
.text-shadow-7 {
  text-shadow: 6px 4px 5px rgba(0, 0, 0, 0.2);
}
.text-shadow-8 {
  text-shadow: 7px 5px 5px rgba(0, 0, 0, 0.2);
}
.text-shadow-9 {
  text-shadow: 8px 5px 6px rgba(0, 0, 0, 0.2);
}
.text-shadow-10 {
  text-shadow: 9px 6px 6px rgba(0, 0, 0, 0.2);
}
.text-shadow-11 {
  text-shadow: 11px 6px 7px rgba(0, 0, 0, 0.2);
}
.text-shadow-12 {
  text-shadow: 12px 7px 8px rgba(0, 0, 0, 0.2);
}
.text-shadow-13 {
  text-shadow: 13px 7px 8px rgba(0, 0, 0, 0.2);
}
.text-shadow-14 {
  text-shadow: 14px 7px 9px rgba(0, 0, 0, 0.2);
}
.text-shadow-15 {
  text-shadow: 15px 8px 9px rgba(0, 0, 0, 0.2);
}
.text-shadow-16 {
  text-shadow: 16px 8px 11px rgba(0, 0, 0, 0.2);
}
.text-shadow-17 {
  text-shadow: 17px 8px 11px rgba(0, 0, 0, 0.2);
}
.text-shadow-18 {
  text-shadow: 18px 9px 11px rgba(0, 0, 0, 0.2);
}
.text-shadow-19 {
  text-shadow: 19px 9px 12px rgba(0, 0, 0, 0.2);
}
.text-shadow-20 {
  text-shadow: 20px 11px 13px rgba(0, 0, 0, 0.2);
}
.text-shadow-21 {
  text-shadow: 21px 11px 13px rgba(0, 0, 0, 0.2);
}
.text-shadow-22 {
  text-shadow: 22px 11px 14px rgba(0, 0, 0, 0.2);
}
.text-shadow-23 {
  text-shadow: 23px 11px 14px rgba(0, 0, 0, 0.2);
}
.text-shadow-24 {
  text-shadow: 24px 11px 15px rgba(0, 0, 0, 0.2);
}
@font-face {
  font-family: "Inter-Regular";
  src: url("@/assets/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter-Thin";
  src: url("@/assets/fonts/Inter-Thin.ttf");
}
@font-face {
  font-family: "Inter-Light";
  src: url("@/assets/fonts/Inter-Light.ttf");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("@/assets/fonts/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("@/assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("@/assets/fonts/Inter-Medium.ttf");
}
@font-face {
  font-family: "Jura-Bold";
  src: url("@/assets/fonts/Jura-Bold.ttf");
}
@font-face {
  font-family: "Jura-Regular";
  src: url("@/assets/fonts/Jura-Regular.ttf");
}
</style>

<template>
  <v-toolbar id="goto" style="background-color: #060505">
    <v-btn
      @click="$vuetify.goTo(link, options)"
      plain
      textretain-focus-on-click
      block
      class="font-weight-light"
    >
      <v-icon color="#ffffff" size="96">mdi-chevron-down</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  data: () => ({
    link: "#about",
    options: {
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
    },
  }),
};
</script>

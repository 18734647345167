<template>
  <v-card id="home">
    <v-img
      ref="hero"
      src="@/assets/img/heroimageSRTest05.jpg"
      class="d-flex text-center justify-center justify-center align-center"
    >
      <v-img
        ref="title"
        src="@/assets/img/SilentRain_Title.png"
        class="d-flex text-center justify-center align-cente"
        contain
        max-height="350"
      />
      <v-btn
        class="text-thin-white2 text-center text-uppercase text-shadow-24"
        href="https://store.steampowered.com/app/1791910/Silent_Rain/"
        target="_blank"
      >
        NOW AVAILABLE ON STEAM
      </v-btn>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: "HeroImg",
  data: () => ({
    nav: null,
    arrow: null,
  }),
  methods: {
    rescaleHero() {
      const viewportHeight = document.documentElement.clientHeight;
      this.$refs.hero.height =
        viewportHeight - this.nav?.clientHeight - this.arrow?.clientHeight;
    },
  },
  mounted() {
    this.nav = document.getElementById("home");
    this.arrow = document.getElementById("goto");
    this.rescaleHero();
    window.addEventListener("resize", this.rescaleHero);
  },
};
</script>

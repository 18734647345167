<template>
  <v-container fluid id="socials">
    <v-card-title class="title-thin-white justify-center text-uppercase my-5">
      Socials
    </v-card-title>
    <p class="text-center">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :href="`mailto:${email.address}`"
            text
            v-bind="attrs"
            v-on="on"
            color="#ffffff"
          >
            <v-icon>{{ email.icon }}</v-icon>
            {{ email.label ?? email.address }}
          </v-btn>
        </template>
        <span>{{ email.tooltip }}</span>
      </v-tooltip>
      <v-card-subtitle class="text-thin-white2 text-center text-shadow-24">
        Sorry, we are not currently looking for more talent, interns or
        apprentices.
      </v-card-subtitle>
    </p>
    <v-card>
      <v-img
        src="@/assets/img/Social_Reclusa_ba.png"
        class="buttons d-flex justify-center justify-center align-center"
      >
        <v-container fluid>
          <v-row justify="center" no-gutters class="m-0 p-0">
            <v-col
              xs="1"
              md="3"
              v-for="media in social_medias"
              :key="media.url"
            >
              <v-row no-gutters class="mx-2 my-4">
                <v-col>
                  <v-card
                    hover
                    dark
                    color="#060505"
                    class="text-center pt-3"
                    :href="media.url"
                    target="_blank"
                  >
                    <v-icon v-if="media.icon" size="4rem" class="mt-1">
                      {{ media.icon }}
                    </v-icon>
                    <v-avatar
                      v-if="media.img"
                      tile
                      class="mx-auto mt-2"
                      size="4rem"
                    >
                      <v-img
                        v-if="media.label == 'Artstation'"
                        src="@/assets/img/artstation.png"
                      />
                      <v-img
                        v-if="media.label == 'X'"
                        src="@/assets/img/xlogo.png"
                      />
                      <v-img
                        v-if="media.label == 'Discord'"
                        src="@/assets/img/discordlogo.png"
                      />
                    </v-avatar>
                    <v-card-text
                      class="text-center font-weight-regular text-h6"
                    >
                      {{ media.label }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    email: {
      icon: "mdi-email",
      tooltip: "Let's talk !",
      address: "contact@seedlersinteractive.com",
    },
    social_medias: [
      {
        img: "@/assets/img/artstation.png",
        url: "https://www.artstation.com/seedlersinteractive",
        label: "Artstation",
      },
      {
        img: "mdi-discord",
        url: "https://discord.gg/aP5XnXVuGP",
        label: "Discord",
      },
      {
        icon: "mdi-instagram",
        url: "https://www.instagram.com/seedlersinteractive/",
        label: "Instagram",
      },
      {
        img: "@/assets/img/xlogo.png",
        url: "https://x.com/SeedlersInt",
        label: "X",
      },
    ],
  }),
};
</script>

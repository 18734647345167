<template>
  <v-app-bar id="home" app color="#060505" elevation="4" elevate-on-scroll>
    <div class="d-flex align-center">
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="@/assets/img/SLI_logoWhite_.png"
        transition="scale-transition"
        width="35"
      >
      </v-img>
    </div>
    <div class="text-thin-white1" style="padding-left: 20px">
      SEEDLERS INTERACTIVE
    </div>
    <v-spacer></v-spacer>

    <div class="hidden-sm-and-down" v-for="link in links" :key="link.href">
      <v-btn
        @click="$vuetify.goTo(link.url, options)"
        text
        style="color: #ffffff"
      >
        <span class="mr-2">{{ link.label }}</span>
      </v-btn>
      <v-divider vertical></v-divider>
    </div>
    <v-btn class="hidden-md-and-up" icon @click="dialog = true">
      <v-icon color="#FFFFFF">mdi-menu</v-icon>
    </v-btn>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card style="background-color: var(--v-background-base)">
        <v-toolbar flat elevation="4">
          <v-toolbar-title>
            <v-img
              width="48"
              height="48"
              src="@/assets/img/SLI_logoWhite_.png"
            />
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container fluid>
          <v-row justify="center" no-gutters class="m-0 p-0">
            <v-col
              cols="12"
              sm="6"
              md="3"
              v-for="link in links"
              :key="link.url"
            >
              <v-row no-gutters class="mx-2 my-4">
                <v-col>
                  <v-card
                    hover
                    dark
                    color="var(--v-secondary-base)"
                    class="text-center pt-3"
                    target="_blank"
                    @click="
                      dialog = false;
                      $vuetify.goTo(link.url, options);
                    "
                  >
                    <v-icon size="4rem" class="mt-1">
                      {{ link.icon }}
                    </v-icon>
                    <v-card-text
                      class="text-center font-weight-regular text-h6"
                    >
                      {{ link.label }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",

  data: () => ({
    dialog: false,
    options: {
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
    },
    links: [
      {
        label: "Home",
        url: "#home",
        icon: "mdi-home",
      },
      {
        label: "About",
        url: "#about",
        icon: "mdi-book",
      },
      {
        label: "Socials",
        url: "#socials",
        icon: "mdi-account-group",
      },
    ],
  }),
};
</script>
